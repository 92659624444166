<script>
export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  props: {
    plan: {
      default: null,
    }
  }
};
</script>

<template>
  <div class="card" v-if="plan">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="plan.name">
          <p class="mb-1">{{ plan.name }}</p>
          <h2 class="mb-1 text-default">{{ plan.description }}</h2>
          <h5 class="mb-0 text-dark">{{ plan.value | currency }} / mês</h5>
        </div>
        <div class="d-flex" v-if="plan.service" style="gap: 15px;">
          <div>
            <svg v-if="plan.service.mentorfy == 'active'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(17, 47, 71, 1);">
              <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
              <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(206, 212, 218, 1);">
              <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
              <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>