<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import Plan from "@/components/widgets/plan-card";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    Plan,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      ip: null,

      statData: null,

      client: null,
      plan: null,
      plans: null,

      choose: {
        modal: false,
        loading: false,
        id: '',
        name: '',
        description: '',
        value: '',
        service: [],
      },

      card: {
        loading: false,
        status: false,
        brand: "",
        number: "",
        expiryMonth: "",
        expiryYear: "",
        cvv: "",
        holderName: "",
      },

      confirm: {
        loading: false,
      },

      payment: {
        loading: false,
      }
    };
  },
  validations: {
    card: {
      number: { required },
      expiryMonth: { required },
      expiryYear: { required },
      cvv: { required },
      holderName: { required },
    },
  },
  methods: {
    getIp() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          if (data.ip) {
            this.ip = data.ip;
          }
        });
    },
    getCurrent() {
      this.plan = null;

      this.statData = null;

      this.choose.modal = false;
      this.choose.loading = false;
      this.choose.id = '';
      this.choose.name = '';
      this.choose.description = '';
      this.choose.value = '';
      this.choose.service = [];

      this.card.status = false;
      this.card.brand = "";
      this.card.number = "";
      this.card.expiryMonth = "";
      this.card.expiryYear = "";
      this.card.cvv = "";
      this.card.holderName = "";

      api
        .get('plans/current')
        .then((response) => {
          if (response.data.status == 'success') {
            this.client = response.data.client
            this.plan = response.data.current

            if (this.client.status == 'await_plan') {
              this.step = 'choose';
            }

            if (this.client.status == 'plan_chosen') {
              this.showPayment();
            }

            if (this.client.status == 'active') {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.client.status,
                },
                {
                  title: "Vencimento",
                  value: this.client.date_due,
                },
                {
                  title: "Valor",
                  value: this.$options.filters.currency(this.plan.value),
                }
              ]
            }
          }
        })
    },
    getPlans() {
      this.plans = null;

      api
        .get('plans')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plans = response.data.list;
          }
        })
    },
    showChoose(plan) {
      this.choose.modal = false;
      this.choose.modal = true;
      this.choose.id = plan.id;
      this.choose.name = plan.name;
      this.choose.description = plan.description;
      this.choose.value = plan.value;
      this.choose.service = plan.service;
    },
    choosePlan() {
      if (this.choose.id) {
        this.choose.loading = true;

        api
          .post('plans/choose', {
            id: this.choose.id
          })
          .then((response) => {
            if (response.data.status == 'success') {
              if (response.data.message) {
                this.$toast.success(response.data.message);
              }

              this.getCurrent();
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.choose.modal = false;
              this.choose.loading = false;
            }
          })
          .finally(() => {
            this.choose.modal = false;
            this.choose.loading = false;
          });
      }
    },
    changePlan() {
      this.client = null;
      this.plan = null;
      this.plans = null;

      api
        .post('plans/change')
        .then((response) => {
          if (response.data.status == 'success') {
            this.getCurrent();
            this.getPlans();
          } else {
            this.$toast.error(response.data.message);
          }
        })
    },
    createPayment() {
      this.$v.card.$touch();
      if (this.$v.card.$invalid) {
        return;
      } else {
        this.card.loading = true;

        api
          .post('plans/payment', {
            number: this.card.number,
            expiryMonth: this.card.expiryMonth,
            expiryYear: this.card.expiryYear,
            cvv: this.card.cvv,
            holderName: this.card.holderName,
            ip: this.ip
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.client.status = 'confirm_buy';

              this.card.status = true;
              this.card.brand = response.data.card.brand;
              this.card.number = response.data.card.number;
              this.card.expiryMonth = response.data.card.expiryMonth;
              this.card.expiryYear = response.data.card.expiryYear;
              this.card.cvv = '';
              this.card.holderName = response.data.card.holderName;

              this.$v.card.$reset();
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.card.loading = false;
          });
      }
    },
    showPayment() {
      api
        .get('plans/payment')
        .then((response) => {
          if (response.data.status == 'success') {
            this.client.status = 'plan_chosen';

            this.card.status = true;
            this.card.brand = response.data.card.brand;
            this.card.number = response.data.card.number;
            this.card.expiryMonth = response.data.card.expiryMonth;
            this.card.expiryYear = response.data.card.expiryYear;
            this.card.cvv = '';
            this.card.holderName = response.data.card.holderName;
          }
        })
        .catch(() => {
        })
        .finally(() => {
        });
    },
    changePayment() {
      api
        .put('plans/payment')
        .then((response) => {
          if (response.data.status == 'success') {
            this.getCurrent();
          } else {
            this.$toast.error(response.data.message);
          }
        })
    },
    confirmPlan() {
      this.confirm.loading = true;

      api
        .post('plans/confirm', {
          ip: this.ip
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.getCurrent();
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.confirm.loading = false;
        });
    },
    makePayment() {
      this.payment.loading = true;
    }
  },
  mounted() {
    this.getIp()
    this.getCurrent()
    this.getPlans()
    this.getPayments()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Meu Plano</h3>
        <p>Detalhes e benefícios do seu plano.</p>
      </div>
    </div>

    <div v-if="!client && !plans" class="text-center">
      <b-spinner class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <template v-else-if="client && client.status == 'await_plan'">
      <div v-if="plans && plans.length > 0">
        <h5 class="mb-4">Escolha a melhor opção para você</h5>
        <div class="row">
          <div class="col-lg-4" v-for="(row, index) in plans" :key="index">
            <div class="card">
              <div class="card-body">
                <p class="mb-1">{{ row.name }}</p>
                <h2 class="mb-1 text-default">{{ row.description }}</h2>
                <h5 class="mb-3 text-dark">{{ row.value | currency }} / mês</h5>
                <div class="d-flex align-items-center mb-3" v-if="row.service">
                  <svg v-if="row.service.mentorfy == 'active'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(17, 47, 71, 1);">
                    <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
                    <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(206, 212, 218, 1);">
                    <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
                    <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
                  </svg>
                  <div :class="row.service.mentorfy == 'inactive' ? 'text-light' : ''" class="ml-2">
                    Mentorias
                  </div>
                </div>
                <button class="btn btn-default btn-block text-uppercase" v-on:click="showChoose(row)">
                  Escolher
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        Nenhum plano disponível no momento.
      </div>
    </template>
    <template v-else-if="client && client.status == 'plan_chosen'">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h5 class="mb-0">Você escolheu o plano</h5>
        <button class="btn btn-outline-default py-1 rounded-sm" v-on:click="changePlan();">Alterar</button>
      </div>
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body row justify-content-center">
          <b-form class="col-lg-6 col-xl-4" v-if="card && !card.status" @submit.prevent="createPayment()">
            <p class="mb-4">Informe o cartão de crédito para realizar o pagamento do seu plano, o valor do plano será cobrado mensalmente sem comprometer o limite do seu cartão.</p>
            <b-form-group label="Número" label-for="number">
              <b-form-input id="number" v-model="card.number" type="text" :class="{ 'is-invalid': $v.card.number.$error }" required v-mask="'#### #### #### ####'"></b-form-input>
            </b-form-group>
            <div class="row">
              <div class="col-6">
                <b-form-group label="Validade" label-for="expiryDate">
                  <b-input-group>
                    <b-form-input id="expiryMonth" v-model="card.expiryMonth" type="text" placeholder="Mês" maxlength="2" :class="{ 'is-invalid': $v.card.expiryMonth.$error }" required
                      v-mask="'##'"></b-form-input>
                    <b-form-input id="expiryYear" v-model="card.expiryYear" type="text" placeholder="Ano" maxlength="2" :class="{ 'is-invalid': $v.card.expiryYear.$error }" required
                      v-mask="'##'"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group label="CVV" label-for="cvv">
                  <b-form-input id="cvv" v-model="card.cvv" type="text" :class="{ 'is-invalid': $v.card.cvv.$error }" required v-mask="'###'"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-form-group label="Nome do titular" label-for="holderName">
              <b-form-input id="holderName" v-model="card.holderName" type="text" :class="{ 'is-invalid': $v.card.holderName.$error }" required></b-form-input>
            </b-form-group>
            <button class="btn btn-default btn-block text-uppercase" type="submit" :disabled="this.card.loading">
              Avançar
              <b-spinner v-if="card.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
          </b-form>
          <div v-else-if="card && card.brand">
            <p>Cartão de Crédito</p>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div>
                <p class="m-0 font-size-15"><strong>{{ card.brand }}</strong></p>
                <p class="m-0 font-size-15">Terminando em {{ card.number }}</p>
                <p class="m-0 font-size-15">Expira em {{ card.expiryMonth }}/{{ card.expiryYear }}</p>
                <p class="m-0 font-size-15">{{ card.holderName }}</p>
              </div>
              <div>
                <button class="btn btn-link text-default p-0" v-on:click="changePayment()">Alterar</button>
              </div>
            </div>
            <button class="btn btn-default btn-block text-uppercase" v-on:click="client.status = 'confirm_buy'">
              Avançar
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'confirm_buy'">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h5 class="mb-0">Para finalizar confirme o seu plano e a forma de pagamento</h5>
      </div>
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="mb-3" v-if="card && card.brand">
            <p>Cartão de Crédito</p>
            <div>
              <p class="m-0 font-size-15"><strong>{{ card.brand }}</strong></p>
              <p class="m-0 font-size-15">Terminando em {{ card.number }}</p>
              <p class="m-0 font-size-15">Expira em {{ card.expiryMonth }}/{{ card.expiryYear }}</p>
              <p class="m-0 font-size-15">{{ card.holderName }}</p>
            </div>
          </div>
          <hr class="my-4">
          <p class="font-size-13">Ao clicar em finalizar você autoriza a cobrança recorrente em seu cartão de crédito.</p>
          <button class="btn btn-default btn-block text-uppercase" v-on:click="confirmPlan()" :disabled="this.confirm.loading">
            Finalizar
            <b-spinner v-if="confirm.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-default btn-block text-uppercase" v-on:click="client.status = 'plan_chosen'">
            Voltar
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'await_payment'">
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M13 3h4v2h-4zM3 8h4v2H3zm0 8h4v2H3zm-1-4h3.99v2H2zm19.707-5.293-1.414-1.414L18.586 7A6.937 6.937 0 0 0 15 6c-3.859 0-7 3.141-7 7s3.141 7 7 7 7-3.141 7-7a6.968 6.968 0 0 0-1.855-4.73l1.562-1.563zM16 14h-2V8.958h2V14z">
                </path>
              </svg>
            </div>
            <div>
              <h6 class="mb-2">Aguarde!</h6>
              <h6 class="font-weight-normal mb-0">Estamos processando o seu pagamento!</h6>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'payment_processing'">
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path
                  d="M13 3h4v2h-4zM3 8h4v2H3zm0 8h4v2H3zm-1-4h3.99v2H2zm19.707-5.293-1.414-1.414L18.586 7A6.937 6.937 0 0 0 15 6c-3.859 0-7 3.141-7 7s3.141 7 7 7 7-3.141 7-7a6.968 6.968 0 0 0-1.855-4.73l1.562-1.563zM16 14h-2V8.958h2V14z">
                </path>
              </svg>
            </div>
            <div>
              <h6 class="mb-2">Aguarde!</h6>
              <h6 class="font-weight-normal mb-0">Estamos processando o seu pagamento!</h6>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'payment_pending'">
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(255, 0, 0, 1);">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
              </svg>
            </div>
            <div>
              <strong>O pagamento do seu plano está pendente!</strong>
            </div>
          </div>
          <div class="mb-3">
            Não conseguimos realizar a cobrança em seu cartão de crédito cadastrado.
          </div>
          <router-link tag="a" to="/pagamentos" class="btn btn-outline-default btn-block">
            Alterar forma de pagamento
          </router-link>
          <button class="btn btn-default btn-block mt-3" v-on:click="makePaymentReprocess();" :disabled="this.reprocess.loading">
            Realizar pagamento
            <b-spinner v-if="reprocess.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'payment_success'">
      <Plan :plan="plan" />
    </template>
    <template v-else-if="client && client.status == 'active'">
      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
          <Stat :title="stat.title" :value="stat.value" />
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="font-weight-medium mb text-uppercase font-weight-light">SERVIÇOS</p>
          <div class="d-flex align-items-center mb-2" v-if="plan.service">
            <svg v-if="plan.service.mentorfy == 'active'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(17, 47, 71, 1);">
              <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
              <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(206, 212, 218, 1);">
              <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
              <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
            </svg>
            <div :class="plan.service.mentorfy == 'inactive' ? 'text-light' : ''" class="ml-2">
              Mentorias
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'overdue'">
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(255, 0, 0, 1);">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
              </svg>
            </div>
            <div>
              <strong>O pagamento do seu plano está em atraso!</strong>
            </div>
          </div>
          <div class="mb-3">
            Não conseguimos realizar a cobrança em seu cartão de crédito cadastrado.
          </div>
          <router-link tag="a" to="/payments" class="btn btn-outline-default btn-block">
            Alterar forma de pagamento
          </router-link>
          <button class="btn btn-default btn-block mt-3" v-on:click="makePayment();" :disabled="this.payment.loading">
            Realizar pagamento
            <b-spinner v-if="payment.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'suspended'">
      <Plan :plan="plan" />
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(255, 0, 0, 1);">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
              </svg>
            </div>
            <div>
              <strong>O seu plano está suspenso por falta de pagamento!</strong>
            </div>
          </div>
          <button class="btn btn-default btn-block mt-3" v-on:click="makePayment();" :disabled="this.payment.loading">
            Realizar pagamento
            <b-spinner v-if="payment.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'canceled_client'">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(255, 0, 0, 1);">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
              </svg>
            </div>
            <div>
              <strong>Seu plano está cancelado!</strong>
            </div>
          </div>
          <button class="btn btn-default btn-block mt-3" v-on:click="changePlan();" :disabled="!this.plans">
            Escolher novo plano
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'canceled_overdue'">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(255, 0, 0, 1);">
                <path d="M11.953 2C6.465 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.493 2 11.953 2zM13 17h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
              </svg>
            </div>
            <div>
              <strong>Seu plano está cancelado por falta de pagamento!</strong>
            </div>
          </div>
          <button class="btn btn-default btn-block mt-3" v-on:click="changePlan();" :disabled="!this.plans">
            Escolher novo plano
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="client && client.status == 'inactive'">
      <Plan :plan="plan" />
    </template>

    <b-modal v-model="choose.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Confirmar escolha</h4>
        <p>Confirma a escolha do plano <strong>{{ choose.name }}</strong>?</p>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-default mr-3" v-on:click="choosePlan()" :disabled="this.choose.loading">
            Confirmar
            <b-spinner v-if="choose.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <a class="btn btn-outline-link" v-on:click="choose.modal = false">Fechar</a>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>